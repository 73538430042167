<template>
  <header-layout v-model="settings" />  
  <div class="container-xxl">
    <div class="row">
      <draggable-column v-model="column1" group="group1" :breakpoints="breakpoints2" :disabled="!settings.enabled" />
      <draggable-column v-model="column2" group="group1" :breakpoints="breakpoints1" :disabled="!settings.enabled" />
      <draggable-column v-model="column3" group="group1" :breakpoints="breakpoints1" :disabled="!settings.enabled" />
    </div>
  </div>
  <modal-button modal-id="testModal" value="Klick mich hart!" />
  <modal modal-id="testModal">
    <button-primary @click="onClick">Item hinzufügen</button-primary>
  </modal>
  <div id="modal-container">

  </div>
</template>

<script>
import HeaderLayout from "./layout/HeaderLayout.vue"
import Modal from "./bootstrap/Modal.vue"
import ModalButton from "./bootstrap/ModalButton.vue"
import ButtonPrimary from "./bootstrap/ButtonPrimary.vue"
import DraggableColumn from "./DraggableColumn.vue"

export default {
  data() {
    return {
      counter: 100,
      columns: [[], [], []],
      breakpoints1: {
        smallest: 12,
        md: 6,
        lg: 3
      },
      breakpoints2: {
        smallest: 12,
        lg: 6
      },
      settings: {
        enabled: false
      },
      // Verwaltet, ob der Nutzer
      // das Dashboard manipuliert hat
      hasChanged: false
    }
  },
  computed: {
    column1: {
      get() {
        return this.columns[0]
      },
      set(newValue) {
        this.columns[0] = newValue
      }
    },
    column2: {
      get() {
        return this.columns[1]
      },
      set(newValue) {
        this.columns[1] = newValue
      }
    },
    column3: {
      get() {
        return this.columns[2]
      },
      set(newValue) {
        this.columns[2] = newValue
      }
    },
  },
  watch: {
    // hier mit v-on:change arbeiten? -> https://stackoverflow.com/questions/41201331/array-watch-with-vue-js-2
    columns(newColumns, oldColumns) {
      console.log("columns")
      this.hasChanged = true
    },
    settings(newSettings, oldSettings) {
      console.log("settings")
      if(newSettings.enabled === false && oldSettings.enabled === true) {
        this.updateDashboard()
      }
    }
  },
  methods: {
    onClick() {
      //this.list1.push({title: "Item " + this.counter++, id: this.counter})
    },
    async updateDashboard() {
      // Nur updaten, wenn der Nutzer etwas manipuliert hat
      // und der Bearbeitungsmodus verlassen wurde (dann ist
      // shouldUpdate auf true)
      if(!this.hasChanged) {
        return
      }

      const response = await fetch("http://localhost:80/v1/users/1/dashboard", {
        method: "POST",
        body: JSON.stringify({
          userId: 1,
          layoutidentifier: this.layoutidentifier,
          widgetConfigurations: this.columns.flat() 
        })
      })
      const body = await response.json()
      console.log({response: body})
    },
    async fetchDashboard() {
      const response = await fetch("http://localhost:80/v1/users/1/dashboard")
      const body = await response.json()
      body["widgetConfigurations"].forEach(el => {
        console.log({el: el})
        this.columns[el["column"] - 1].push(el)
      })
      this.layoutidentifier = body["layoutIdentifier"]
    }
  },
  created() {
    this.fetchDashboard();
  },
  components: {
    HeaderLayout,
    Modal,
    ModalButton,
    ButtonPrimary,
    DraggableColumn,
  }
}
</script>

<style>

</style>