import './scss/styles.scss'
import "bootstrap"

import debounce from 'debounce'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import WindowState from './states/window.js'

window.addEventListener('resize', debounce(function() {
    WindowState.updateWindowWidth()
}), 200)

createApp(App).mount('#app')
