<template>
  <draggable-widget-container :title="title" cssClasses="bundesliga-widget" :size="size">
    <table class="table table-dark">
      <thead>
        <th>#</th>
        <th></th>
        <th v-for="(t, key) in tableHeader" :key="key">
          {{t}}
        </th>
        <th>Punkte</th>
      </thead>
      <tbody>
        <tr v-for="(teamInfo, index) in tableData" :key="teamInfo.TeaminfoId">
          <td>{{index+1}}</td>
          <td><img :src="teamInfo.TeamIconUrl" class="team-icon" /></td>
          <td v-for="(teamProp, key) in displayedTeamProps" :key="key">
            {{teamInfo[teamProp]}}
          </td>
          <td>{{teamInfo.Points}}</td>
        </tr>
      </tbody>
    </table>
  </draggable-widget-container>
</template>

<script>
import { calculateComponentSize } from '../../utils/componet.js'
import WindowState from '../../states/window.js'
import DraggableWidgetContainer from './DraggableWidgetContainer.vue'

// Wie kann man schon vor dem Rendering abschätzen, wie breit
// die Component sein wird? -> über Bildschirmgröße und breakpoints?

export default {
  props: {
    breakpoints: Object
  },
  data() {
    return {
      title: "Bundesliga",
      tableData: [],
      windowState: WindowState.state
    }
  },
  computed: {
    size() {
      return calculateComponentSize(this.breakpoints, this.windowState.width)
    },
    displayedTeamProps() {
      switch(this.size) {
        case "lg": return ["TeamName"]
        case "sm": return ["ShortName"]
        default: return []
      }
    },
    tableHeader() {
      switch(this.size) {
        case "lg": 
        case "sm": return ["Mannschaft"]
        default: return []
      }
    }
  },
  methods: {
    async fetchData() {
      const response = await fetch("http://localhost:80/v1/sports/soccer/bundesliga")
      const body = await response.json()
      this.tableData = body
    }
  },
  created() {
    this.fetchData()
  },
  components: { DraggableWidgetContainer },
}
</script>