<template>
  <svg :class="css" width="32" height="32" fill="currentColor">
    <use :xlink:href="fullSpriteName"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true
    },
    cssClasses: {
      type: String,
      required: false
    }
  },
  computed: {
    fullSpriteName() {
      return "bootstrap-icons.svg#" + this.iconName
    },
    css() {
      let cls = "bi"
      if(this.cssClasses) {
        cls += " " + this.cssClasses
      }
      return cls
    }
  }
}
</script>

<style>

</style>