<template>
  <header class="navbar container-fluid">
    <div>
      <a id="mainNavigationBtn" data-bs-toggle="offcanvas" href="#mainNavigation" role="button" aria-controls="mainNavigation"><bs-icon-list /></a>
      <h1 class="brand-name">Dashtab</h1>
    </div>
    <div class="form-check form-switch">
      <label class="form-check-label" for="editingModeSwitch">Bearbeitungsmodus</label>
      <input v-model="settings.enabled" class="form-check-input" type="checkbox" id="editingModeSwitch" />
    </div>
  </header>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="mainNavigation" aria-labelledby="mainNavigationLabel">
    <div class="offcanvas-header justify-content-end">
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div>
        Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
      </div>
    </div>
  </div>
</template>

<script>
import BsIconList from "../bootstrap/icons/BsIconList.vue"

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    settings: {
        get() {
            return this.modelValue
        },
        set(value) {
            this.$emit('update:modelValue', value)
        }
    },
  },
  components: {
    BsIconList
  }
}
</script>