<template>
  <div :class="fullCssClasses">
    <div v-if="editingMode">
      <modal-link :modalId="modalId">
        <bs-icon icon-name="gear-fill" cssClasses="settings-icon" />
      </modal-link>
    </div>
    <h2 v-if="title" class="h4">{{title}}<slot name="after-title"></slot></h2>
    <slot></slot>
    <template>
      <modal :modal-id="modalId">
        <template v-slot:title>
          Einstellungen für "{{ title }}"
        </template>
        <template v-slot:default>
          Settings {{ modalId }} -> {{ title }}
        </template>
      </modal>
    </template>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';


import Modal from '../bootstrap/Modal.vue'
import ModalLink from '../bootstrap/ModalLink.vue'
import BsIcon from '../bootstrap/icons/BsIcon.vue';

export default {
  props: {
    cssClasses: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default() {
        return "lg"
      }
    },
    editingMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalId: "modal-" + uuid()
    }
  },
  computed: {
    fullCssClasses() {
      let classes = "draggable-item widget widget-size-" + this.size 
      if(this.cssClasses) {
        classes += " " + this.cssClasses
      }
      return classes
    }
  },
  components: { Modal, ModalLink, BsIcon },
}
</script>