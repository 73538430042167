<template>
  <draggable-widget-container :title="title" cssClasses="twitch-widget" :editing-mode="editingMode">
    <template v-slot:after-title>
      <div v-if="anyStreamerOnline" class="on-air"></div>
    </template>
    <template v-slot:default>
      <div class="d-flex flex-wrap">
        <span v-if="!anyStreamerOnline" class="h6">Kein Stream online.</span>
        <div v-for="streamer in streamerOnline" :key="streamer.id" class="d-flex stream-container">
          <div>
            <img :src="streamer.profile_image_url" class="streamer-profile-image" />
          </div>
          <div class="stream-detail">
            <span class="streamer h6">{{ streamer.display_name }}</span>
            <span class="game">{{ streamer.stream.game_name }}</span>
          </div>
        </div>
      </div>
    </template>
  </draggable-widget-container>
</template>

<script>
import DraggableWidgetContainer from './DraggableWidgetContainer.vue'

export default {
  props: {
    breakpoints: Object,
    editingMode: Boolean
  },
  data() {
    return {
      title: "Live",
      streamer: []
    }
  },
  computed: {
    streamerOnline() {
      return this.streamer.filter((streamer) => streamer.stream != null)
    },
    anyStreamerOnline() {
      return this.streamerOnline.length > 0 ? true : false
    }
  },
  methods: {
    async fetchData() {
      const response = await fetch("http://localhost:80/v1/twitch?user_login[]=gronkh&user_login[]=thejocraft_live&user_login[]=Blizzor")
      const body = await response.json()
      this.streamer = body
    }
  },
  created() {
    this.fetchData()
  },
  components: {
    DraggableWidgetContainer
  },
}
</script>