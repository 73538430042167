<template>
  <bs-icon icon-name="list" />
</template>

<script>
import BsIcon from './BsIcon.vue'

export default {
  components: { BsIcon },
}
</script>

<style>

</style>