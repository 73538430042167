<template>
  <draggable-widget-container :title="title" cssClasses="news-widget">
    <div v-for="item in newsItems" :key="item.title" class="news-item">
      <h3 class="h6">{{item.title}}</h3>
      <p>{{item.description}}</p>
      <a :href="item.link" class="stretched-link">Zum Artikel</a>
    </div>
  </draggable-widget-container>
</template>

<script>
import { calculateComponentSize } from '../../utils/componet.js'
import DraggableWidgetContainer from "./DraggableWidgetContainer.vue"

export default {
  props: {
    breakpoints: Object
  },
  data() {
    return {
      title: "News",
      newsItems: []
    }
  },
  methods: {
    async fetchNews() {
      const response = await fetch("http://localhost:80/v1/news?limit=5")
      const body = await response.json()
      this.title = body["title"]
      this.newsItems = body["entries"]
    }
  },
  mounted() {
    console.log(calculateComponentSize(this.breakpoints))
  },
  created() {
    this.fetchNews()
  },
  components: {
    DraggableWidgetContainer
  }
}
</script>