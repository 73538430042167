<template>
  <draggable-widget-container>
    Dummy Drag
  </draggable-widget-container>
</template>

<script>
import DraggableWidgetContainer from "./DraggableWidgetContainer.vue"

export default {
  components: {
    DraggableWidgetContainer
  }
}
</script>

<style>

</style>