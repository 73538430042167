<template>
  <button type="button" class="btn btn-primary"><slot></slot></button>
</template>

<script>
export default {

}
</script>

<style>

</style>