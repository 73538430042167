const BS_BREAKPOINTS = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576
}


export function calculateComponentSize(breakpoints, windowWidth = window.innerWidth) {
    const containerMaxWidth = BS_BREAKPOINTS.xxl
    const mainContainerWidth = windowWidth < containerMaxWidth ? windowWidth : containerMaxWidth

    let currentColWidth = breakpoints.smallest
    for(let w in BS_BREAKPOINTS) {
        if(mainContainerWidth >= BS_BREAKPOINTS[w] && breakpoints[w]) {
            currentColWidth = breakpoints[w]
            break
        }
    }

    let currentComponentWidth = (currentColWidth / 12) * mainContainerWidth - 24 // 24 kommt vom Padding bzw. Grid-Gutter
    //return currentComponentWidth

    if(currentComponentWidth >= 500) {
        return "lg"
    }
    if(currentComponentWidth >= 320) {
        return "sm"
    }
    return "xs"
}