<template>
  <a data-bs-toggle="modal" @click="moveModal">
    <slot></slot>
  </a>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  props: {
    modalId: {
      type: String,
      required: true
    }
  },
  computed: {
    fullModalId() {
      return "#" + this.modalId
    }
  },
  methods: {
    moveModal(event) {
      const modalElement = document.querySelector(this.fullModalId)
      const modalContainer = document.querySelector("#modal-container")
      modalContainer.innerHTML = modalElement.outerHTML
      const modal = Modal.getOrCreateInstance(modalContainer.querySelector(this.fullModalId))
      modal.show()
    }
  }
}
</script>