<template>
  <div :class="breakpointClasses">
    <draggable v-model="items" item-key="id" :group="group" :disabled="disabled" :class="draggableClasses">
      <template #item="{element}">
        <component :is="element.frontendIdentifier" :breakpoints="breakpoints" :editing-mode="!disabled"></component>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import DummyWidget from "./widgets/DummyWidget.vue"
import NewsWidget from "./widgets/NewsWidget.vue"
import BundesligaWidget from "./widgets/BundesligaWidget.vue"
import TwitchWidget from "./widgets/TwitchWidget.vue"

export default {
  props: {
    breakpoints: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    items: {
        get() {
            return this.modelValue
        },
        set(value) {
            this.$emit('update:modelValue', value)
        }
    },
    draggableClasses() {
      return this.disabled ? "drag-disabled draggable-container" : "draggable-container drag-enabled"
    },
    breakpointClasses() {
      const bp = this.breakpoints
      const bootstrapBreakpoints = ["sm", "md", "lg", "xl", "xxl"]
      let colStr = "col-" + this.breakpoints.smallest
      bootstrapBreakpoints.forEach(el => {
        if(bp[el]) {
          colStr += " col-" + el + "-" + bp[el]
        }
      });
      return colStr
    }
  },
  components: {
    draggable,
    DummyWidget,
    NewsWidget,
    BundesligaWidget,
    TwitchWidget
  }
}
</script>